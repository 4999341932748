'use strict'
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { MotionPathPlugin } from "gsap/dist/MotionPathPlugin";
import { PixiPlugin } from "gsap/dist/PixiPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, MotionPathPlugin, PixiPlugin);


/* /////////// */
/*   ANNIM     */
/* ////////// */

let titreh1 = document.querySelector("h1");
let match = gsap.matchMedia();

/*  ACCEUIL  */

match.add("(max-width: 799px)", () => {

    
       
    gsap.to('.animDesktop',{duration:1,opacity:1,scale:1.5, delay:0.5, ease:"smooth"});
    gsap.from('.avatar', {duration:1,x:"100",ease:"smooth",opacity:1});

})


match.add("(min-width: 800px) ", () => {

    gsap.to(".animDesktop", {duration: 1, opacity: 1, scale: 2, delay: 0.5, ease: "smooth"});
    gsap.from('.avatar', {duration: 1.5, x: 500, ease: "smooth", opacity: 1});
   

    
});